import Service from ".";
import type { LiftingGroupForm } from "../components/Meets/MeetSetup/types";
import type {
  AthleteWithAllResults,
  UUID,
  AthleteOrderWithResults,
  LiftingGroup,
  AthleteOrderWithResultsAndLiftingGroup,
  MeetAthleteWithResults,
  MeetConfig,
  MeetSchool,
  Meet,
  Result,
  MutateMeetAthlete,
  MeetPermission,
  UserPermission,
  MeetAthlete,
} from "../types";

export type CreateMeet = Omit<Meet, "id" | "hostUser">;
export type EditMeet = Meet;
class MeetService extends Service {
  constructor() {
    super({ path: "/php/MeetMain.php" });
  }

  createMeet(
    formData: CreateMeet,
    liftingGroups: LiftingGroupForm[],
    teamIds?: UUID[]
  ): Promise<UUID> {
    return this.post({
      task: "createMeet",
      formData,
      liftingGroups,
      teamIds,
    });
  }

  updateMeetSetup(
    formData: EditMeet,
    liftingGroups: LiftingGroupForm[]
  ): Promise<UUID> {
    return this.post({
      task: "updateMeetSetup",
      formData,
      liftingGroups,
    });
  }

  updateAllFlights(meetId: UUID): Promise<UUID> {
    return this.post({
      task: "updateAllFlights",
      meetId,
    });
  }

  setActiveEvent(liftingGroupId: UUID, event: Event): Promise<UUID> {
    return this.post({
      task: "setActiveEvent",
      liftingGroupId,
      event,
    });
  }

  getMeetConfig(meetId: UUID): Promise<MeetConfig> {
    return this.get({
      task: "getMeetConfig",
      meetId,
    });
  }

  getWeightClassesAthleteCount(
    meetId: UUID,
    schoolId?: UUID
  ): Promise<Record<UUID, number>> {
    return this.get({
      task: "getWeightClassesAthleteCount",
      meetId,
      schoolId,
    });
  }

  getMeetSchool(meetId: UUID, schoolId: UUID): Promise<MeetSchool> {
    return this.get({
      task: "getMeetSchool",
      meetId,
      schoolId,
    });
  }

  getMeetSchoolAthletes(
    meetId: UUID,
    schoolId: UUID
  ): Promise<MeetAthleteWithResults[]> {
    return this.get({
      task: "getMeetSchoolAthletes",
      meetId,
      schoolId,
    });
  }

  getMeetSchoolAthletesForWeightClass(
    meetId: UUID,
    schoolId: UUID,
    weightClassId: UUID
  ): Promise<MeetAthleteWithResults[]> {
    return this.get({
      task: "getMeetSchoolAthletesForWeightClass",
      meetId,
      schoolId,
      weightClassId,
    });
  }

  getResultsForWeightClass(
    meetId: UUID,
    weightClassId: UUID
  ): Promise<AthleteWithAllResults[]> {
    return this.get({
      task: "getResultsForWeightClass",
      meetId,
      weightClassId,
    });
  }

  getOrderForLiftingGroup(
    meetId: UUID,
    liftGroupId: UUID
  ): Promise<AthleteOrderWithResults[]> {
    return this.get({
      task: "getOrderForLiftingGroup",
      meetId,
      liftGroupId,
    });
  }

  getLiftingGroup(liftGroupId: UUID): Promise<LiftingGroup> {
    return this.get({
      task: "getLiftingGroup",
      liftGroupId,
    });
  }

  getLiftingGroups(meetId: UUID): Promise<LiftingGroup[]> {
    return this.get({
      task: "getLiftingGroups",
      meetId,
    });
  }

  getNextAthleteInGroup(
    meetId: UUID,
    liftGroupId: UUID
  ): Promise<AthleteOrderWithResults> {
    return this.get({
      task: "getNextAthleteInGroup",
      meetId,
      liftGroupId,
    });
  }

  getAllAthletesAndResults(
    meetId: UUID
  ): Promise<AthleteOrderWithResultsAndLiftingGroup[]> {
    return this.get({
      task: "getAllAthletesAndResults",
      meetId,
    });
  }

  getMeetAthlete(
    meetAthleteId: UUID,
    onlyActiveEvent: boolean
  ): Promise<MeetAthleteWithResults> {
    return this.get({
      task: "getMeetAthlete",
      meetAthleteId,
      onlyActiveEvent,
    });
  }

  querySchoolAthletesWithResults(
    meetId: UUID,
    schoolId: UUID,
    minimumWeight: number,
    maximumWeight: number,
    gender: string
  ): Promise<MeetAthleteWithResults[]> {
    {
      return this.get({
        task: "querySchoolAthletesWithResults",
        meetId,
        schoolId,
        minimumWeight,
        maximumWeight,
        gender,
      });
    }
  }

  addTeamToMeet(meetId: UUID, teamId: UUID): Promise<void> {
    return this.post({
      task: "addTeamToMeet",
      meetId,
      teamId,
    });
  }

  removeTeamFromMeet(meetId: UUID, teamId: UUID): Promise<void> {
    return this.post({
      task: "removeTeamFromMeet",
      meetId,
      teamId,
    });
  }

  updateAthleteAndResults(
    meetAthlete: MutateMeetAthlete,
    updatedResults: Result[],
    deletedResults: Result[],
    newResults: Result[]
  ): Promise<UUID> {
    return this.post({
      task: "updateAthleteAndResults",
      meetAthlete,
      updatedResults,
      deletedResults,
      newResults,
    });
  }

  getMeetUsers(meetId: UUID): Promise<MeetPermission[]> {
    return this.get({
      task: "getMeetUsers",
      meetId,
    });
  }

  getMeetPermissions(meetId: UUID, userId: UUID): Promise<UserPermission[]> {
    return this.post({
      task: "getMeetPermissions",
      meetId,
      userId,
    });
  }

  addMeetUser(meetId: UUID, permissionType: string): Promise<void> {
    return this.post({
      task: "addMeetUser",
      meetId,
      permissionType,
    });
  }

  getAllAthletes(meetId: UUID): Promise<MeetAthlete[]> {
    return this.get({
      task: "getAllAthletes",
      meetId,
    });
  }

  getTeams(meetId: UUID): Promise<MeetSchool[]> {
    return this.get({
      task: "getTeams",
      meetId,
    });
  }
}

export default new MeetService();
