/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pending } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import useAsync from "../../customHooks/useAsync";
import type { RootState } from "../../data/types";
import MeetService from "../../services/meet";
import type { LiftingGroup, MeetAthleteWithResults, Result } from "../../types";
import { type School, type WeightClass, type Event } from "../../types";
import CenteredCircularProgress from "../common/CenteredCircularProgress";
import DisplayGrid from "../common/DisplayGrid";

export default function MeetAthletePage() {
  const theme = useTheme();
  const { meetAthleteId } = useParams();
  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });

  const { data: meetAthlete, isInitial } = useAsync(() => {
    if (!activeMeet) {
      return null;
    }
    return MeetService.getMeetAthlete(meetAthleteId, false);
  }, [activeMeet.id]);

  if (isInitial) {
    return <CenteredCircularProgress />;
  }

  if (!meetAthlete) {
    return (
      <Stack>
        <Typography variant="h1">Athlete Not Found</Typography>
        <Button
          component={Link}
          to={`/meet/${activeMeet.id}/athletes`}
          variant="contained"
        >
          Back to Athletes
        </Button>
      </Stack>
    );
  }

  const weightClass = activeMeet.weightClasses.find(
    (wc) => wc.id === meetAthlete.weightClass
  );

  return (
    <Stack gap={2} my={2}>
      <Typography variant="h1">{`${meetAthlete.firstName} ${meetAthlete.lastName}`}</Typography>
      <Stack direction="column" gap={1}>
        <Typography variant="h3" color={theme.palette.text.secondary}>
          {(meetAthlete.school as School)?.schoolName ||
            activeMeet.meetSchools.find(
              (school) => school.schoolsId === meetAthlete.school
            )?.schoolName}
        </Typography>
        <Typography variant="h3" color={theme.palette.text.secondary}>
          Weight Class:{" "}
          <Link
            to={`/meet/${activeMeet.id}/results/weightClass/${weightClass.id}`}
          >
            {weightClass.weightClassName}
          </Link>
        </Typography>
        <Typography variant="h3" color={theme.palette.text.secondary}>
          Platform:{" "}
          <Link
            to={`/meet/${activeMeet.id}/liftOrder/${
              (meetAthlete.liftingGroup as LiftingGroup).id
            }`}
          >
            {(meetAthlete.liftingGroup as LiftingGroup).liftingGroupName}
          </Link>
        </Typography>
      </Stack>
      <Stack gap={3}>
        <AthleteResultsTable
          meetAthlete={meetAthlete}
          results={meetAthlete.results}
          eventType="SQUAT"
        />
        <AthleteResultsTable
          meetAthlete={meetAthlete}
          results={meetAthlete.results}
          eventType="BENCH"
        />
        <AthleteResultsTable
          meetAthlete={meetAthlete}
          results={meetAthlete.results}
          eventType="DEADLIFT"
        />
      </Stack>
    </Stack>
  );
}

type AthleteResultsTableProps = {
  meetAthlete: MeetAthleteWithResults;
  results: Result[];
  eventType: Event;
};

function AthleteResultsTable({
  meetAthlete,
  results,
  eventType,
}: AthleteResultsTableProps) {
  const filteredResults = results
    .filter((r) => r.event === eventType)
    .sort((a, b) => {
      return new Date(a.entryTime).getTime() - new Date(b.entryTime).getTime();
    });

  // Add a number of pending rows to make there a total of 3 rows
  for (let i = 0; filteredResults.length < 3; i++) {
    let opener = 0;

    if (eventType === "SQUAT") {
      opener = meetAthlete.startSquat;
    }
    if (eventType === "BENCH") {
      opener = meetAthlete.startBench;
    }
    if (eventType === "DEADLIFT") {
      opener = meetAthlete.startDeadlift;
    }
    filteredResults.push({
      id: `pending-${i}`,
      meet: "pending",
      athlete: "pending",
      liftSuccess: null,
      weight: filteredResults.length === 0 ? opener : 0,
      event: "",
      entryTime: new Date(),
      completionTime: new Date(),
    });
  }

  return (
    <Stack>
      <Typography variant="h4">{eventType}</Typography>
      <DisplayGrid
        data={filteredResults}
        fullHeight={false}
        columns={[
          {
            label: "Attempt",
            isNumber: true,
            width: 100,
            renderer: (r, index) => index + 1,
          },
          {
            label: "Weight",
            isNumber: true,
            renderer: (r) => {
              if (!r.liftSuccess && r.weight === 0) {
                return "Pending...";
              }
              return r.weight;
            },
          },
          {
            label: "Lift Result",
            renderer: (r) => {
              if (!r.liftSuccess) {
                return "N/A";
              }
              return r.liftSuccess === "TRUE" ? (
                <Typography color="success.main">SUCCESS</Typography>
              ) : (
                <Typography color="error.main">FAIL</Typography>
              );
            },
          },
        ]}
      />
    </Stack>
  );
}
