/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useSelector } from "react-redux";

import store from "../../../data";
import AppActions from "../../../data/app/actions";
import MeetActions from "../../../data/meet/actions";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";
import type { LiftingGroupWithWeightClass, MeetConfig } from "../../../types";
import { sortLiftingGroups, sortWeightClasses } from "../../../utils/sortUtils";
import CenteredCircularProgress from "../../common/CenteredCircularProgress";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import "./styles.scss";

export default function MeetManagement() {
  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });

  const liftingGroups = React.useMemo(() => {
    const lgs: LiftingGroupWithWeightClass[] = activeMeet.liftingGroup.map(
      (group) => {
        return {
          ...group,
          weightClasses: activeMeet.weightClasses.filter((weightClass) => {
            return weightClass.liftingGroup === group.id;
          }),
        };
      }
    );

    lgs.map((group) => {
      return group.weightClasses.sort(sortWeightClasses);
    });
    return lgs.sort(sortLiftingGroups);
  }, [activeMeet]);

  return (
    <>
      <Stack direction="row">
        {activeMeet.enableFlights ? (
          <UpdateFlightsButton activeMeet={activeMeet} />
        ) : null}
      </Stack>
      <DataGrid
        className="MeetManagementGrid"
        autoHeight={true}
        rows={liftingGroups || []}
        hideFooterSelectedRowCount={true}
        columns={[
          {
            field: "liftingGroupName",
            headerName: "Name",
          },
          {
            field: "weightClasses",
            headerName: "Weight Classes",
            cellClassName: "weightClassCell",
            flex: 1,
            renderCell: (params) => {
              const weightClassesStr = params.row.weightClasses
                .map((weightClass) => {
                  return weightClass.weightClassName;
                })
                .join(", ");
              return (
                <Tooltip title={weightClassesStr}>
                  <Stack>{weightClassesStr}</Stack>
                </Tooltip>
              );
            },
          },
          {
            field: "activeEvent",
            headerName: "Active Event",
            width: 275,
            renderCell: (params) => {
              return <ToggleEventButtons liftingGroup={params.row} />;
            },
          },
          // {
          //   field: "actions",
          //   headerName: "",
          //   width: 200,
          //   renderCell: (params) => {
          //     return (
          //       <Stack direction="row" gap={1}>
          //         <Button
          //           variant="contained"
          //           onClick={() => handleClickEdit(params.row)}
          //         >
          //           Edit
          //         </Button>
          //         <RemoveButton
          //           meetAthleteId={params.row.meet_athleteId}
          //           reload={reload}
          //           topLevelReload={topLevelReload}
          //         />
          //       </Stack>
          //     );
          //   },
          // },
        ]}
        //   components={{
        //     NoRowsOverlay: () => {
        //       if (isLoading) {
        //         return <CenteredCircularProgress />;
        //       }
        //       return (
        //         <Stack height="100%" alignItems="center" justifyContent="center">
        //           No athletes added for this weight class.
        //         </Stack>
        //       );
        //     },
        //   }}
      />
    </>
  );
}

type UpdateFlightsButtonProps = {
  activeMeet: MeetConfig;
};

function UpdateFlightsButton({ activeMeet }: UpdateFlightsButtonProps) {
  const [showConfirmUpdateAllFlights, setShowConfirmUpdateAllFlights] =
    React.useState<boolean>(false);

  async function handleUpdateAllFlights() {
    try {
      await MeetService.updateAllFlights(activeMeet.id);
      setShowConfirmUpdateAllFlights(false);
    } catch (e) {
      console.error(e);
      await store.dispatch(
        AppActions.setSnackBar({
          text: `Failed to set/update flights for athletes. Please try again.  If this problem persists contact a meet administrator.`,
          severity: "error",
          open: true,
        })
      );
    }
  }
  return (
    <>
      <ConfirmationDialog
        open={showConfirmUpdateAllFlights}
        title="Confirm set/update all flights?"
        message="This will set the flights for all lifters.  If you meet is currently in progress this can have can have negative consequences on the lift order.  If you already manually moved a lifter to a new flight this will override that operation.  Are you sure you want to continue?"
        onConfirm={handleUpdateAllFlights}
        onClose={() => {
          setShowConfirmUpdateAllFlights(false);
        }}
      />
      <Button
        variant="contained"
        onClick={() => setShowConfirmUpdateAllFlights(true)}
      >
        Set/Update All Flights
      </Button>
    </>
  );
}

type ToggleEventButtonsProps = {
  liftingGroup: LiftingGroupWithWeightClass;
};

function ToggleEventButtons({ liftingGroup }: ToggleEventButtonsProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  async function handleChange(
    event: React.MouseEvent<HTMLElement>,
    newAlignment: Event
  ) {
    setIsLoading(true);
    await MeetService.setActiveEvent(liftingGroup.id, newAlignment);
    await store.dispatch(MeetActions.load());
    setIsLoading(false);
  }

  return isLoading ? (
    <CenteredCircularProgress />
  ) : (
    <ToggleButtonGroup
      color="primary"
      value={liftingGroup.activeEvent}
      exclusive={true}
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value="SQUAT">SQUAT</ToggleButton>
      <ToggleButton value="BENCH">BENCH</ToggleButton>
      <ToggleButton value="DEADLIFT">DEADLIFT</ToggleButton>
    </ToggleButtonGroup>
  );
}
