/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import store from "../../../data";
import AppActions from "../../../data/app/actions";
import type { RootState } from "../../../data/types";
import AdminService from "../../../services/admin";
import MainService from "../../../services/main";
import {
  type Athlete,
  type AllResults,
  type MeetAthlete,
  type AthleteOrderWithResultsAndLiftingGroup,
  LiftSuccess,
} from "../../../types";
import DisplayGrid from "../../common/DisplayGrid";

type EditAthleteDialogProps = {
  onClose: () => void;
  open: boolean;
  athlete: MeetAthlete;
  onSuccess: (athlete: Athlete) => void;
};

export default function EditAthleteDialog({
  open,
  onClose,
  athlete,
  onSuccess,
}: EditAthleteDialogProps) {
  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<MeetAthlete>({
    defaultValues: React.useMemo(() => athlete, [athlete]),
  });

  function onSubmit(data: MeetAthlete) {
    setIsLoading(true);
    console.log("submit", data);

    setIsLoading(false);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          Edit {athlete.lastName}, {athlete.firstName}
        </DialogTitle>
        <DialogContent
          dividers={true}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            {...register("bodyWeight", { required: true })}
            error={!!errors.baseBodyWeight}
            label="Body Weight"
            required={true}
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 0.1 }}
          />
          <Controller
            name="weightClass"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth={true}
                select={true}
                label="Weight Class"
                error={!!errors.gender}
                required={true}
                helperText={errors.gender ? errors.gender?.message : ""}
              >
                {activeMeet.weightClasses.map((weightClass) => (
                  <MenuItem key={weightClass.id} value={weightClass.id}>
                    {weightClass.weightClassName}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <TextField
            {...register("baseStartSquat", { required: true })}
            error={!!errors.baseStartSquat}
            label="1st Squat"
            required={true}
            type="number"
            fullWidth={true}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 5 }}
          />
          <TextField
            {...register("baseStartBench", { required: true })}
            error={!!errors.baseStartBench}
            label="1st Bench"
            required={true}
            type="number"
            fullWidth={true}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 5 }}
          />
          <TextField
            {...register("baseStartDeadlift", { required: true })}
            error={!!errors.baseStartDeadlift}
            label="1st Deadlift"
            required={true}
            type="number"
            fullWidth={true}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 5 }}
          />
          <DisplayGrid
            data={athlete.results}
            fullHeight={false}
            columns={[
              {
                label: "Attempt",
                isNumber: true,
                width: 100,
                renderer: (r, index) => index + 1,
              },
              {
                label: "Weight",
                isNumber: true,
                renderer: (r) => {
                  return r.weight;
                },
              },
              {
                label: "Lift Result",
                renderer: (r) => {
                  return r.liftSuccess;
                },
              },
              // {
              //   label: "Delete Entry",
              //   renderer: (r) => {
              //     if (results.length <= 1 && !r.id) {
              //       return null;
              //     }
              //     return (
              //       <DeleteButton
              //         athlete={athlete}
              //         result={r}
              //         onDeleted={handleDeleted}
              //       />
              //     );
              //   },
              // },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton variant="contained" loading={isLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
