/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  FormLabel,
  useTheme,
} from "@mui/material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AddEditWeightClassDialog } from "./AddEditWeightClassDialog";
import { EditPlatformDialog } from "./EditPlatformDialog";
import type { LiftingGroupForm, WeightClassForm } from "./types";
import store from "../../../data";
import AppActions from "../../../data/app/actions";
import MeetActions from "../../../data/meet/actions";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";
import type { MeetConfig, UUID } from "../../../types";
import { SDHSPAWeightClasses } from "../../../utils/defaults";
import { sortLiftingGroups, sortWeightClasses } from "../../../utils/sortUtils";
import ConfirmationDialog from "../../common/ConfirmationDialog";

function getNewUniquePlatformName(liftingGroups: LiftingGroupForm[]) {
  const platformNames = liftingGroups.map((lg) => lg.liftingGroupName);
  for (let i = 1; i <= platformNames.length + 1; i++) {
    if (!platformNames.includes(`Platform ${i}`)) {
      return `Platform ${i}`;
    }
  }
  return `Platform ${platformNames.length + 1}`;
}

type MeetSetupForm = Omit<
  MeetConfig,
  "meetDate" | "rosterCutOffDate" | "hostUser"
> & {
  hostUser: UUID;
  meetDate: string;
  rosterCutOffDate: string;
};

type MeetSetupProps = {
  createMeet?: boolean;
  meetDefaults: Partial<MeetSetupForm>;
  weightClassesCounts?: Record<UUID, number>;
};

export default function MeetSetup({
  createMeet = false,
  meetDefaults,
  weightClassesCounts,
}: MeetSetupProps) {
  const navigate = useNavigate();
  const user = useSelector((state) => (state as RootState).app.user);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    watch,
  } = useForm<MeetSetupForm>({
    defaultValues: meetDefaults,
  });

  const [liftingGroups, setLiftingGroups] = useState<LiftingGroupForm[]>(
    React.useMemo(() => {
      if (meetDefaults.id) {
        return meetDefaults.liftingGroup.map((group) => {
          return {
            ...group,
            weightClasses: meetDefaults.weightClasses.filter((weightClass) => {
              return weightClass.liftingGroup === group.id;
            }),
          };
        });
      }
      return [
        {
          liftingGroupName: "Platform 1",
          weightClasses: SDHSPAWeightClasses.filter(
            (weightClass) => weightClass.gender === "FEMALE"
          ),
        },
        {
          liftingGroupName: "Platform 2",
          weightClasses: SDHSPAWeightClasses.filter(
            (weightClass) => weightClass.gender === "MALE"
          ),
        },
      ];
    }, [meetDefaults])
  );

  const sortedLiftingGroups = React.useMemo(() => {
    liftingGroups.map((group) => {
      return group.weightClasses.sort(sortWeightClasses);
    });
    return liftingGroups.sort(sortLiftingGroups);
  }, [liftingGroups]);

  async function handleFormSubmit(data: MeetSetupForm) {
    setIsSubmitting(true);
    const teamsToJoin: UUID[] = [];
    if (user?.teams) {
      for (const team of user.teams) {
        teamsToJoin.push(team.schoolsId);
      }
    }
    let modifiedMeetId = null;
    if (!meetDefaults.id) {
      modifiedMeetId = await MeetService.createMeet(
        {
          ...data,
          meetDate: new Date(data.meetDate),
          rosterCutOffDate: new Date(data.rosterCutOffDate),
        },
        sortedLiftingGroups,
        teamsToJoin
      );
    } else {
      modifiedMeetId = await MeetService.updateMeetSetup(
        {
          ...data,
          meetDate: new Date(data.meetDate),
          rosterCutOffDate: new Date(data.rosterCutOffDate),
        },
        sortedLiftingGroups
      );
    }

    await store.dispatch(
      AppActions.setSnackBar({
        text: `Successfully ${meetDefaults.id ? "Updated" : "Created"} Meet '${
          data.meetName
        }'!`,
        severity: "success",
        open: true,
      })
    );

    if (!meetDefaults.id) {
      // TODO: add a selector for the team to join.
      if (teamsToJoin.length > 0) {
        navigate(`/meet-roster/${modifiedMeetId}/${teamsToJoin[0]}`);
      } else {
        navigate(`/meet/${modifiedMeetId}`);
      }
    } else {
      await store.dispatch(MeetActions.load());
    }
    setIsSubmitting(false);
  }
  const [openAddEditWeightClassDialog, setOpenAddEditWeightClassDialog] =
    React.useState(false);
  const [openEditPlatformDialog, setOpenEditPlatformDialog] =
    React.useState(false);
  const [confirmationMessage, setConfirmationMessage] =
    React.useState<string>(null);
  const [handleConfirmation, setHandleConfirmation] =
    React.useState<string>(null);

  const [openConfirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);

  const [selectedLiftingGroup, setSelectedLiftingGroup] =
    React.useState<LiftingGroupForm | null>(null);
  const [selectedWeightClass, setSelectedWeightClass] =
    React.useState<WeightClassForm | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [moveAnchorEl, setMoveAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const openMove = Boolean(moveAnchorEl);
  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    liftingGroup: LiftingGroupForm,
    weightClass: WeightClassForm
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedLiftingGroup(liftingGroup);
    setSelectedWeightClass(weightClass);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMoveAnchorEl(null);
  };

  const handleOpenMoveMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMoveAnchorEl(event.currentTarget);
  };
  const handleMoveWeightClass = (liftingGroup: LiftingGroupForm) => {
    if (selectedWeightClass) {
      const newLiftingGroups = liftingGroups.map((lg) => {
        if (lg.liftingGroupName === liftingGroup.liftingGroupName) {
          return {
            ...lg,
            weightClasses: [...lg.weightClasses, selectedWeightClass],
          };
        } else if (
          lg.liftingGroupName === selectedLiftingGroup?.liftingGroupName
        ) {
          return {
            ...lg,
            weightClasses: lg.weightClasses.filter(
              (wc) => wc.weightClassName !== selectedWeightClass.weightClassName
            ),
          };
        } else {
          return lg;
        }
      });
      setLiftingGroups(newLiftingGroups);
      setSelectedLiftingGroup(null);
      setSelectedWeightClass(null);
    }

    setMoveAnchorEl(null);
    setAnchorEl(null);
  };

  const handleRemoveWeightClass = () => {
    setConfirmationMessage(
      "Are you sure you want to remove this weight class?"
    );
    setConfirmationDialogOpen(true);
    setHandleConfirmation("REMOVE_WEIGHT_CLASS");
    setAnchorEl(null);
  };

  const confirmRemoveWeightClass = React.useCallback(() => {
    if (selectedWeightClass) {
      const newLiftingGroups = liftingGroups.map((lg) => {
        if (
          lg.liftingGroupName === selectedLiftingGroup?.liftingGroupName &&
          lg.weightClasses.length > 1
        ) {
          return {
            ...lg,
            weightClasses: lg.weightClasses.filter(
              (wc) => wc.weightClassName !== selectedWeightClass.weightClassName
            ),
          };
        } else {
          return lg;
        }
      });
      setLiftingGroups(newLiftingGroups);
      setSelectedLiftingGroup(null);
      setSelectedWeightClass(null);
    }

    setAnchorEl(null);
  }, [liftingGroups, selectedLiftingGroup, selectedWeightClass]);

  const handleAddPlatform = () => {
    const newLiftingGroups = [
      ...liftingGroups,
      {
        liftingGroupName: getNewUniquePlatformName(liftingGroups),
        weightClasses: [],
      },
    ];
    setLiftingGroups(newLiftingGroups);
  };

  const handleRemovePlatform = (liftingGroup: LiftingGroupForm) => {
    setConfirmationMessage(
      "Are you sure you want to remove this platform? All weight classes will be moved to the first platform."
    );
    setConfirmationDialogOpen(true);
    setSelectedLiftingGroup(liftingGroup);
    setHandleConfirmation("REMOVE_PLATFORM");
  };

  const confirmRemovePlatform = React.useCallback(() => {
    if (selectedLiftingGroup) {
      const newLiftingGroups = liftingGroups.filter(
        (lg) => lg.liftingGroupName !== selectedLiftingGroup.liftingGroupName
      );
      if (newLiftingGroups.length > 0) {
        newLiftingGroups[0].weightClasses.push(
          ...selectedLiftingGroup.weightClasses
        );
      }
      setLiftingGroups(newLiftingGroups);
      setSelectedLiftingGroup(null);
      setSelectedWeightClass(null);
    }
  }, [liftingGroups, selectedLiftingGroup]);

  const confirmationHandler = React.useCallback(() => {
    if (handleConfirmation === "REMOVE_PLATFORM") {
      confirmRemovePlatform();
    }
    if (handleConfirmation === "REMOVE_WEIGHT_CLASS") {
      confirmRemoveWeightClass();
    }
    setHandleConfirmation(null);
    setConfirmationDialogOpen(false);
  }, [confirmRemovePlatform, confirmRemoveWeightClass, handleConfirmation]);

  const otherLiftingGroups = liftingGroups.filter(
    (lg) => lg.liftingGroupName !== selectedLiftingGroup?.liftingGroupName
  );

  const handleAddEditWeightClass = (
    weightClass: WeightClassForm,
    liftingGroup: LiftingGroupForm
  ): void => {
    let newLiftingGroups = liftingGroups;
    if (selectedWeightClass) {
      newLiftingGroups = liftingGroups.map((lg) => {
        if (
          lg.liftingGroupName === selectedLiftingGroup?.liftingGroupName &&
          lg.weightClasses.length > 1
        ) {
          return {
            ...lg,
            weightClasses: lg.weightClasses.filter(
              (wc) => wc.weightClassName !== selectedWeightClass.weightClassName
            ),
          };
        } else {
          return lg;
        }
      });
    }
    newLiftingGroups = newLiftingGroups.map((lg) => {
      if (lg.liftingGroupName === liftingGroup?.liftingGroupName) {
        return {
          ...lg,
          weightClasses: [...lg.weightClasses, weightClass],
        };
      } else {
        return lg;
      }
    });
    setLiftingGroups(newLiftingGroups);
    setSelectedLiftingGroup(null);
    setSelectedWeightClass(null);
    setOpenAddEditWeightClassDialog(false);
  };

  const handleOpenEditPlatformDialog = (liftingGroup: LiftingGroupForm) => {
    setSelectedLiftingGroup(liftingGroup);
    setOpenEditPlatformDialog(true);
  };

  const handleEditPlatform = (liftingGroup: LiftingGroupForm) => {
    let newLiftingGroups = liftingGroups;
    if (selectedLiftingGroup) {
      newLiftingGroups = liftingGroups.filter(
        (lg) => lg.liftingGroupName !== selectedLiftingGroup.liftingGroupName
      );
    }
    newLiftingGroups = [
      ...newLiftingGroups,
      {
        ...liftingGroup,
        weightClasses: selectedLiftingGroup?.weightClasses || [],
      },
    ];
    setLiftingGroups(newLiftingGroups);
    setSelectedLiftingGroup(null);
    setSelectedWeightClass(null);
    setOpenEditPlatformDialog(false);
  };

  const handleEditWeightClass = () => {
    if (selectedWeightClass) {
      setOpenAddEditWeightClassDialog(true);
    }
    setAnchorEl(null);
  };

  function onEditorStateChange(editorState: unknown) {
    setValue(
      "rulesText",
      // @ts-ignore
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    setEditorState(editorState);
  }

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(meetDefaults.rulesText))
    )
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack gap={1} sx={{ width: "100%" }}>
        <Typography variant="h2">
          {meetDefaults.id ? "Edit Meet Setup" : "Create a Meet"}
        </Typography>
        <TextField
          {...register("meetName", { required: true })}
          margin="dense"
          label="Name"
          type="text"
          fullWidth={true}
          error={!!errors.meetName}
        />
        <TextField
          {...register("meetDate", { required: true })}
          margin="dense"
          label="Meet Date/Time"
          type="datetime-local"
          fullWidth={true}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: createMeet ? meetDefaults.meetDate : undefined,
          }}
          error={!!errors.meetDate}
        />
        <TextField
          {...register("rosterCutOffDate", { required: true })}
          margin="dense"
          label="Roster Cutoff Date/Time"
          type="datetime-local"
          fullWidth={true}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: createMeet ? meetDefaults.rosterCutOffDate : undefined,
            max: watch("meetDate"),
          }}
          error={!!errors.rosterCutOffDate}
        />
        <FormLabel>Rules/Description:</FormLabel>
        <Box
          className="editor"
          sx={{
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ".rdw-editor-main": {
              outline: `1px solid ${theme.palette.divider}`,
              borderRadius: theme.shape.borderRadius,
              padding: theme.spacing(1),
              minHeight: 200,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              "&:focus-within": {
                outlineColor: theme.palette.primary.main,
                outlineWidth: 2,
              },
            },
          }}
        >
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            rows={10}
          />
        </Box>
        <TextField
          {...register("defaultAthletesPerSchool", { required: true })}
          margin="dense"
          label="Max Athletes Per School"
          type="number"
          fullWidth={true}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          error={!!errors.defaultAthletesPerSchool}
        />
        <FormControlLabel
          label="Use Flights"
          control={
            <Controller
              name="enableFlights"
              control={control}
              render={({ field }) => {
                return <Checkbox checked={!!field.value} {...field} />;
              }}
            />
          }
        />
        <Collapse in={!!watch("enableFlights")}>
          <TextField
            {...register("flightCount", { required: true })}
            margin="dense"
            label="Number of Flights"
            type="number"
            fullWidth={true}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            error={!!errors.flightCount}
          />
        </Collapse>
        {/* Show these fields when we enable Team scoring */}
        {/*         
        <TextField
          {...register("scorersPerWeightClassPerSchool", { required: true })}
          margin="dense"
          label="Max Scorers Per School Per Weight Class"
          type="number"
          fullWidth={true}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          error={!!errors.scorersPerWeightClassPerSchool}
        />
        <TextField
          {...register("scorersPerSchool", { required: true })}
          margin="dense"
          label="Max Total Scorers Per School"
          type="number"
          fullWidth={true}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          error={!!errors.scorersPerSchool}
        /> */}
        <DisplayPlatforms
          onClickAddWeightClass={() => setOpenAddEditWeightClassDialog(true)}
          onClickRemovePlatform={handleRemovePlatform}
          onClickEditPlatform={handleOpenEditPlatformDialog}
          onClickAddPlatform={handleAddPlatform}
          onClickOpenMenu={handleOpenMenu}
          liftingGroups={sortedLiftingGroups}
          weightClassesCounts={weightClassesCounts}
        />

        <Stack
          sx={{ position: "sticky", bottom: 0 }}
          py={2}
          direction="row"
          justifyContent="flex-end"
        >
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            type="submit"
          >
            {meetDefaults.id ? "Update Meet" : "Create New Meet"}
          </LoadingButton>
        </Stack>
        <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
          <MenuItem
            disabled={otherLiftingGroups.length <= 0}
            onClick={handleOpenMoveMenu}
          >
            <Stack direction="row" justifyContent="space-between" gap={2}>
              <ListItemText>Move to</ListItemText>

              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleEditWeightClass}>Edit</MenuItem>
          <MenuItem onClick={handleRemoveWeightClass}>Remove</MenuItem>
        </Menu>
        <Menu
          anchorEl={moveAnchorEl}
          open={openMove}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {otherLiftingGroups.map((lg) => {
            return (
              <MenuItem
                key={lg.liftingGroupName}
                onClick={() => handleMoveWeightClass(lg)}
              >
                {lg.liftingGroupName}
              </MenuItem>
            );
          })}
        </Menu>
        <ConfirmationDialog
          message={confirmationMessage}
          open={openConfirmationDialogOpen}
          onClose={() => setConfirmationDialogOpen(false)}
          onConfirm={confirmationHandler}
        >
          <Typography>
            Are you sure you want to remove this weight class?
          </Typography>
        </ConfirmationDialog>
        <AddEditWeightClassDialog
          open={openAddEditWeightClassDialog}
          onClose={() => setOpenAddEditWeightClassDialog(false)}
          onSubmit={handleAddEditWeightClass}
          weightClass={selectedWeightClass}
          liftingGroup={selectedLiftingGroup}
          liftingGroups={liftingGroups}
        />
        <EditPlatformDialog
          open={openEditPlatformDialog}
          onClose={() => setOpenEditPlatformDialog(false)}
          liftingGroup={selectedLiftingGroup}
          onSubmit={handleEditPlatform}
          liftingGroups={liftingGroups}
        />
      </Stack>
    </form>
  );
}

type DisplayPlatformsProps = {
  onClickAddPlatform: () => void;
  onClickAddWeightClass: () => void;
  onClickRemovePlatform: (liftingGroup: LiftingGroupForm) => void;
  onClickEditPlatform: (liftingGroup: LiftingGroupForm) => void;
  onClickOpenMenu: (
    event: React.MouseEvent<HTMLButtonElement>,
    liftingGroup: LiftingGroupForm,
    weightClass: WeightClassForm
  ) => void;
  liftingGroups: LiftingGroupForm[];
  weightClassesCounts?: Record<UUID, number>;
};
function DisplayPlatforms({
  onClickAddPlatform,
  onClickAddWeightClass,
  onClickRemovePlatform,
  onClickEditPlatform,
  onClickOpenMenu,
  liftingGroups,
  weightClassesCounts,
}: DisplayPlatformsProps) {
  return (
    <Box
      p={3}
      border="1px solid"
      borderColor="divider"
      borderRadius={3}
      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
    >
      <Stack direction="row" alignItems="center" gap={1} mb={1}>
        <Button
          color="success"
          onClick={onClickAddPlatform}
          variant="contained"
        >
          Add Platform
        </Button>
        <Button
          color="success"
          onClick={onClickAddWeightClass}
          variant="contained"
        >
          Add Weight Class
        </Button>
      </Stack>
      <Box
        display="grid"
        rowGap={2}
        sx={{
          gridTemplateColumns: `repeat(auto-fill, minmax(350px, 1fr))`,
        }}
      >
        {liftingGroups.map((liftingGroup, index) => {
          const athleteCount = liftingGroup.weightClasses.reduce(
            (acc, weightClass) => {
              if (!weightClassesCounts || !weightClass.id) {
                return 0;
              }
              return acc + (weightClassesCounts[weightClass.id] || 0);
            },
            0
          );
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} px={1}>
              <Stack
                direction="row"
                gap={1}
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Typography variant="h3">
                  {liftingGroup.liftingGroupName}
                </Typography>
                <Stack direction="row" gap={1} alignItems="center">
                  {weightClassesCounts ? (
                    <Chip label={athleteCount} size="small" />
                  ) : null}
                  <IconButton onClick={() => onClickEditPlatform(liftingGroup)}>
                    <EditIcon color="primary" />
                  </IconButton>
                  {liftingGroups.length > 1 ? (
                    <IconButton
                      onClick={() => onClickRemovePlatform(liftingGroup)}
                    >
                      <DeleteOutlineIcon color="error" />
                    </IconButton>
                  ) : null}
                </Stack>
              </Stack>
              <DisplayWeightClasses
                onClickOpenMenu={onClickOpenMenu}
                liftingGroup={liftingGroup}
                weightClasses={liftingGroup.weightClasses}
                weightClassesCounts={weightClassesCounts}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

type DisplayWeightClassesProps = {
  onClickOpenMenu: (
    event: React.MouseEvent<HTMLButtonElement>,
    liftingGroup: LiftingGroupForm,
    weightClass: WeightClassForm
  ) => void;
  liftingGroup: LiftingGroupForm;
  weightClasses: WeightClassForm[];
  weightClassesCounts: Record<UUID, number>;
};
function DisplayWeightClasses({
  onClickOpenMenu,
  liftingGroup,
  weightClasses,
  weightClassesCounts,
}: DisplayWeightClassesProps) {
  function handleClickOpenMenu(
    event: React.MouseEvent<HTMLButtonElement>,
    weightClass: WeightClassForm
  ) {
    onClickOpenMenu(event, liftingGroup, weightClass);
  }
  return (
    <Stack direction="column" maxWidth={400} divider={<Divider />}>
      {weightClasses.map((weightClass, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Stack key={index} direction="row" gap={2} alignItems="center">
            <Stack
              flex={1}
              gap={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">
                {weightClass.weightClassName}
              </Typography>
              {weightClassesCounts ? (
                <Chip
                  label={weightClassesCounts[weightClass.id]}
                  size="small"
                />
              ) : null}
            </Stack>
            {weightClass.gender === "MALE" ? (
              <MaleIcon htmlColor="blue" />
            ) : (
              <FemaleIcon htmlColor="pink" />
            )}
            <Typography width={74} textAlign="right" variant="body1">
              {weightClass.maxWeight} lbs
            </Typography>
            <IconButton
              onClick={(event) => handleClickOpenMenu(event, weightClass)}
            >
              <MoreVertIcon />
            </IconButton>
          </Stack>
        );
      })}
    </Stack>
  );
}
