import Service from ".";
import type { Athlete, School, UUID } from "../types";

export type EditAthleteData = Partial<Athlete>;

class AdminService extends Service {
  constructor() {
    super({ path: "/php/AdminMain.php" });
  }

  deleteAthlete(id: UUID) {
    return this.post({
      task: "deleteAthlete",
      id,
    });
  }

  editAthlete(formData: EditAthleteData) {
    return this.post({
      task: "editAthlete",
      modifiedVars: formData,
    });
  }

  getCoaches() {
    return this.get({
      task: "getCoaches",
    });
  }

  getTeams(): Promise<School[]> {
    return this.get({
      task: "getTeams",
    });
  }

  getSession(): Promise<Object> {
    return this.get({
      task: "getSession",
    });
  }
}

export default new AdminService();
