import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Outlet, Link } from "react-router-dom";

import "./App.css";
import CoachConsole from "./components/CoachConsole";
import Meets from "./components/CoachConsole/Meets";
import TeamManagement from "./components/CoachConsole/TeamManagement";
import MCConsole from "./components/MCConsole";
import MeetApp from "./components/MeetApp";
import AdminConsole from "./components/MeetApp/AdminConsole";
import AllAthletes from "./components/MeetApp/AllAthletes";
import AthletesManager from "./components/MeetApp/AthletesManager";
import MeetHome from "./components/MeetApp/Home";
import LiftEntryAthlete from "./components/MeetApp/LiftEntry/LiftEntryAthlete";
import LiftEntryGroup from "./components/MeetApp/LiftEntry/LiftEntryGroup";
import NextLiftEntry from "./components/MeetApp/LiftEntry/NextLiftEntry";
import LiftOrder from "./components/MeetApp/LiftOrder";
import MeetAthletePage from "./components/MeetApp/MeetAthletePage";
import MeetTeams from "./components/MeetApp/MeetTeams";
import MeetUsers from "./components/MeetApp/MeetUsers";
import Rules from "./components/MeetApp/Rules";
import WeightClass from "./components/MeetApp/results/WeightClass";
import EditAthletePage from "./components/Meets/EditAthletePage";
import MeetManagement from "./components/Meets/MeetManagement";
import MeetRoster from "./components/Meets/MeetRoster";
import CreateMeet from "./components/Meets/MeetSetup/CreateMeet";
import EditMeet from "./components/Meets/MeetSetup/EditMeet";
import MeetsGrid from "./components/Meets/MeetsGrid";
import MySnackBar from "./components/MySnackBar";
import { CoachRegistration } from "./components/Registration/CoachRegistration";
import SiteAdminConsole from "./components/SiteAdminConsole";
import TopBar from "./components/TopBar";
import CenteredCircularProgress from "./components/common/CenteredCircularProgress";
import store from "./data";
import AppActions from "./data/app/actions";
import type { RootState } from "./data/types";

// import 'dotenv/config';

// require('dotenv').config();
// console.log(process.env);

type MainContentWrapperProps = {
  children: React.ReactNode;
};

function MainContentWrapper({ children }: MainContentWrapperProps) {
  return <div className="main-content">{children}</div>;
}

export default function App() {
  const initialized = useSelector((state) => {
    return (state as RootState).app.initialized;
  });

  useEffect(() => {
    if (!initialized) {
      // Initialize the Redux store
      store.dispatch(AppActions.initialize());
    }
  }, [initialized]);

  return (
    <div className="App">
      <div className="container">
        <TopBar />
        <Routes>
          <Route
            path="/"
            element={initialized ? <Outlet /> : <CenteredCircularProgress />}
          >
            <Route
              index={true}
              element={
                <MainContentWrapper>
                  <Home />
                </MainContentWrapper>
              }
            />
            <Route
              path="admin"
              element={
                <MainContentWrapper>
                  <SiteAdminConsole />
                </MainContentWrapper>
              }
            />
            <Route
              path="create-meet"
              element={
                <MainContentWrapper>
                  <CreateMeet />
                </MainContentWrapper>
              }
            />
            <Route path="registration">
              <Route
                path="coach"
                element={
                  <MainContentWrapper>
                    <CoachRegistration />
                  </MainContentWrapper>
                }
              />
            </Route>
            <Route path="coach" element={<CoachConsole />}>
              <Route
                index={true}
                element={<MainContentWrapper>coach</MainContentWrapper>}
              />
              <Route
                path="meets"
                element={
                  <MainContentWrapper>
                    <Meets />
                  </MainContentWrapper>
                }
              />
              <Route
                path="teamManagement"
                element={
                  <MainContentWrapper>
                    <TeamManagement />
                  </MainContentWrapper>
                }
              >
                <Route
                  path=":teamId"
                  element={
                    <MainContentWrapper>
                      <TeamManagement />
                    </MainContentWrapper>
                  }
                />
              </Route>
            </Route>
            <Route path="meet/:meetId" element={<MeetApp />}>
              <Route
                path=""
                element={
                  <MainContentWrapper>
                    <MeetHome />
                  </MainContentWrapper>
                }
              />
              <Route
                path="admin"
                element={
                  <MainContentWrapper>
                    <AdminConsole />
                  </MainContentWrapper>
                }
              >
                <Route path="setup" element={<EditMeet />} />
                <Route path="management" element={<MeetManagement />} />
                <Route path="athletes" element={<AthletesManager />} />
                <Route path="users" element={<MeetUsers />} />
                <Route path="teams" element={<MeetTeams />} />
              </Route>
              <Route
                path="team/:teamId"
                element={
                  <MainContentWrapper>
                    <TeamManagement />
                  </MainContentWrapper>
                }
              />
              <Route
                path="allAthletes"
                element={
                  <MainContentWrapper>
                    <AllAthletes />
                  </MainContentWrapper>
                }
              />
              <Route
                path="athlete/:meetAthleteId"
                element={
                  <MainContentWrapper>
                    <MeetAthletePage />
                  </MainContentWrapper>
                }
              />
              <Route
                path="rules"
                element={
                  <MainContentWrapper>
                    <Rules />
                  </MainContentWrapper>
                }
              />
              <Route
                path="results/weightClass/:weightClassId"
                element={
                  <MainContentWrapper>
                    <WeightClass />
                  </MainContentWrapper>
                }
              />
              <Route
                path="liftOrder/:liftGroupId"
                element={
                  <MainContentWrapper>
                    <LiftOrder />
                  </MainContentWrapper>
                }
              />
              <Route
                path="liftOrder/:liftGroupId/headJudge"
                element={
                  <MainContentWrapper>
                    <LiftOrder isHeadJudge={true} />
                  </MainContentWrapper>
                }
              />
              <Route
                path="liftEntryGroup/:liftGroupId"
                element={
                  <MainContentWrapper>
                    <LiftEntryGroup />
                  </MainContentWrapper>
                }
              />
              <Route
                path="nextLiftEntry"
                element={
                  <MainContentWrapper>
                    <NextLiftEntry />
                  </MainContentWrapper>
                }
              />
              <Route
                path="liftEntryAthlete/:meetAthleteId"
                element={
                  <MainContentWrapper>
                    <LiftEntryAthlete />
                  </MainContentWrapper>
                }
              />
              <Route
                path="editAthlete/:meetAthleteId"
                element={
                  <MainContentWrapper>
                    <EditAthletePage />
                  </MainContentWrapper>
                }
              />
              <Route path="mc" element={<MCConsole />} />
            </Route>

            <Route
              path="meet-roster/:meetId/:schoolId"
              element={
                <MainContentWrapper>
                  <MeetRoster />
                </MainContentWrapper>
              }
            />
            <Route
              path="meet/*"
              element={<MainContentWrapper>Meet not found.</MainContentWrapper>}
            />

            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route
              path="*"
              element={
                <MainContentWrapper>
                  <NoMatch />
                </MainContentWrapper>
              }
            />
          </Route>
        </Routes>
        <MySnackBar />
      </div>
    </div>
  );
}

function Home() {
  return (
    <Stack gap={1} flex={1}>
      <Typography variant="h1">Meets</Typography>
      <Stack marginBottom={4} flex={1}>
        <MeetsGrid />
      </Stack>
    </Stack>
  );
}

function NoMatch() {
  return (
    <div>
      <Typography variant="h1">Nothing to see here!</Typography>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
