/* eslint-disable @typescript-eslint/no-unused-vars */
import { Collapse, Link, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";

import store from "../../data";
import AppActions from "../../data/app/actions";
import AuthService from "../../services/auth";

type LoginProps = {
  onClose: () => void;
  open: boolean;
};

type LoginFormMode = "login" | "forgotPassword" | "resetPassword";

function getTitle(formMode: LoginFormMode) {
  switch (formMode) {
    case "login":
      return "Log In";
    case "forgotPassword":
      return "Account Recovery";
    case "resetPassword":
      return "Reset Password";
  }
}

function getSubmitText(formMode: LoginFormMode) {
  switch (formMode) {
    case "login":
      return "Log In";
    case "forgotPassword":
      return "Get Code";
    case "resetPassword":
      return "Reset Password";
  }
}

export default function Login({ open, onClose }: LoginProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [formMode, setFormMode] = useState<LoginFormMode>("login");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    resetCode: "",
  });

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });
  }

  async function onSubmit() {
    switch (formMode) {
      case "login":
        try {
          const userResponse = await AuthService.login(
            formData.email,
            formData.password
          );

          if (userResponse) {
            await store.dispatch(AppActions.setUser(userResponse));

            await store.dispatch(
              AppActions.setSnackBar({
                text: "Successfully signed in!",
                severity: "success",
                open: true,
              })
            );
            onClose();
          }
        } catch (error) {
          if (error.message) {
            if (error.message === "Incorrect username or password.") {
              await store.dispatch(
                AppActions.setSnackBar({
                  text: "Incorrect email or password.",
                  severity: "error",
                  open: true,
                })
              );
              return;
            }

            await store.dispatch(
              AppActions.setSnackBar({
                text: "Error logging in!  Please try again. If the problem persists, please contact the site admin for help.",
                severity: "error",
                open: true,
              })
            );

            console.log(error.message);
          }
        }
        break;
      case "forgotPassword":
        try {
          // TODO: Do something with response.
          const forgotPasswordResponse = await AuthService.forgotPassword(
            formData.email
          );
          await store.dispatch(
            AppActions.setSnackBar({
              text: "Successfully sent reset code!",
              severity: "success",
              open: true,
            })
          );
          setFormMode("resetPassword");
        } catch (error) {
          await store.dispatch(
            AppActions.setSnackBar({
              text: "Error sending reset code!",
              severity: "error",
              open: true,
            })
          );
        }

        break;
      case "resetPassword":
        try {
          // TODO: Do something with response.
          const resetPasswordResponse = await AuthService.resetPassword(
            formData.email,
            formData.password,
            formData.resetCode
          );
          await store.dispatch(
            AppActions.setSnackBar({
              text: "Successfully reset password!",
              severity: "success",
              open: true,
            })
          );
          setFormMode("login");
        } catch (error) {
          await store.dispatch(
            AppActions.setSnackBar({
              text: "Error resetting password!",
              severity: "error",
              open: true,
            })
          );
        }
        break;
    }
  }

  function handleKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Enter") {
      onSubmit();
    }
  }

  return (
    <Dialog open={open} onClose={onClose} onKeyUp={handleKeyUp}>
      <DialogTitle>{getTitle(formMode)}</DialogTitle>
      <DialogContent>
        <Collapse in={formMode === "login"}>
          <Typography variant="body1">
            Enter your email address and password to sign in to your account.
          </Typography>
        </Collapse>
        <Collapse in={formMode === "forgotPassword"}>
          <Typography variant="body1">
            Enter your e-mail and we will send you a reset code to your e-mail
            address.
          </Typography>
        </Collapse>
        <Collapse in={formMode === "resetPassword"}>
          <Typography variant="body1">
            If your account was found, an e-mail has been sent to the associated
            e-mail address. Enter the code and your new password.
          </Typography>
        </Collapse>
        <Stack component="form" mt={2} gap={2}>
          <Collapse in={formMode === "login" || formMode === "forgotPassword"}>
            <TextField
              autoFocus={true}
              margin="dense"
              name="email"
              label="Email Address"
              type="email"
              fullWidth={true}
              value={formData.email}
              onChange={handleInputChange}
            />
          </Collapse>
          <Collapse in={formMode === "resetPassword"}>
            <TextField
              autoFocus={true}
              margin="dense"
              name="resetCode"
              label="Reset Code"
              fullWidth={true}
              value={formData.resetCode}
              onChange={handleInputChange}
            />
          </Collapse>
          <Collapse in={formMode === "login" || formMode === "resetPassword"}>
            <TextField
              margin="dense"
              name="password"
              label="Password"
              type="password"
              fullWidth={true}
              value={formData.password}
              onChange={handleInputChange}
            />
          </Collapse>
        </Stack>
        <Collapse in={formMode === "login"}>
          <Link
            onClick={() => setFormMode("forgotPassword")}
            component="button"
          >
            Forgot password?
          </Link>
        </Collapse>
        <Collapse in={formMode === "forgotPassword"}>
          <Link onClick={() => setFormMode("login")} component="button">
            Log in
          </Link>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit}>{getSubmitText(formMode)}</Button>
      </DialogActions>
    </Dialog>
  );
}
