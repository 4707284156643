/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Stack,
  TextField,
  DialogActions,
  LinearProgress,
} from "@mui/material";
import type { GridRowParams } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";

import { AddUpdateMeetAthlete } from "./AddUpdateMeetAthlete";
import useAsync from "../../customHooks/useAsync";
import store from "../../data";
import AppActions from "../../data/app/actions";
import CoachService from "../../services/coach";
import MeetService from "../../services/meet";
import type { MutateMeetAthlete, UUID, WeightClass } from "../../types";
import { sortWeightClasses } from "../../utils/sortUtils";
import CenteredCircularProgress from "../common/CenteredCircularProgress";

type ModifyWeightClassRosterDialogProps = {
  open: boolean;
  onClose: () => void;
  meetId: UUID;
  schoolId: UUID;
  weightClass: WeightClass;
  weightClasses: WeightClass[];
  initialAthleteIds: UUID[];
  athleteCount: number;
  maxAthletes: number;
};

export default function ModifyWeightClassRosterDialog({
  open,
  onClose,
  meetId,
  schoolId,
  weightClass,
  weightClasses,
  initialAthleteIds,
  athleteCount,
  maxAthletes,
}: ModifyWeightClassRosterDialogProps) {
  const [isSubmitLoading, setSubmitLoading] = React.useState(false);
  const [minimumWieght, setMinimumWeight] = React.useState(0);
  const [selectedAthleteIds, setSelectedAthleteIds] = React.useState([]);

  React.useEffect(() => {
    if (open === false) {
      return;
    }
    setSelectedAthleteIds(initialAthleteIds);
  }, [open, initialAthleteIds]);

  React.useEffect(() => {
    if (open === false) {
      return;
    }
    const filteredWeightClasses = weightClasses
      .filter((wc) => wc.gender === weightClass.gender)
      .sort(sortWeightClasses);
    const weightClassIndex = filteredWeightClasses.findIndex(
      (wc) => wc.id === weightClass.id
    );
    const previousWeightClass = filteredWeightClasses[weightClassIndex - 1];
    if (previousWeightClass) {
      setMinimumWeight(previousWeightClass.maxWeight + 0.1);
    }
  }, [open, weightClass, weightClasses]);

  const { data, isLoading } = useAsync(async () => {
    if (open === false) {
      return Promise.resolve([]);
    }
    const athletes = await MeetService.querySchoolAthletesWithResults(
      meetId,
      schoolId,
      minimumWieght,
      weightClass.maxWeight,
      weightClass.gender
    );

    return athletes.map((athlete, index) => {
      return {
        ...athlete,
        // TODO: Get the best weights.  For now just use the base weights.
        bestBench: athlete.baseStartBench,
        bestSquat: athlete.baseStartSquat,
        bestDeadlift: athlete.baseStartDeadlift,
      };
    });
  }, [open, minimumWieght]);

  function handleSelectAthlete(ids: UUID[]) {
    setSelectedAthleteIds(ids);
  }

  React.useEffect(() => {
    if (open === true) {
      setActiveStep(0);
      setStagedAthletes([]);
    }
  }, [open]);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    // Skip the first step because that is when we add the athletes.
    if (activeStep > 0) {
      stepRefs.current[activeStep - 1]?.next();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const stepRefs = React.useRef([]);

  React.useEffect(() => {
    stepRefs.current = stepRefs.current.slice(0, selectedAthleteIds.length); // Initialize to the right length
  }, [selectedAthleteIds]);

  const [stagedAthletes, setStagedAthletes] = React.useState<
    MutateMeetAthlete[]
  >([]);

  const handleUpdateAthlete = React.useCallback(
    async (athlete: MutateMeetAthlete) => {
      const newAths = stagedAthletes.filter((a) => a.id !== athlete.id);
      newAths.push(athlete);

      if (activeStep < selectedAthleteIds.length) {
        setStagedAthletes([...newAths]);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      // Final step so we can submit and close.
      else {
        const removeAthleteIds = initialAthleteIds.filter(
          (id) => selectedAthleteIds.includes(id) === false
        );

        try {
          setSubmitLoading(true);

          await CoachService.updateAthletesForMeet(
            meetId,
            newAths.map((a) => ({
              weightClass: weightClass.id,
              ...a,
            })),
            removeAthleteIds
          );
          store.dispatch(
            AppActions.setSnackBar({
              text: `Successfully updated athletes for the meet!`,
              severity: "success",
              open: true,
            })
          );
          onClose();
        } catch (error) {
          store.dispatch(
            AppActions.setSnackBar({
              text: `Failed to update!`,
              severity: "error",
              open: true,
            })
          );
        }
        setSubmitLoading(false);
      }
    },
    [
      activeStep,
      initialAthleteIds,
      meetId,
      onClose,
      selectedAthleteIds,
      stagedAthletes,
      weightClass.id,
    ]
  );

  function handleSubmit() {
    // Skip the first step because that is when we add the athletes.
    if (activeStep > 0) {
      stepRefs.current[activeStep - 1]?.next();
      return;
    }
  }

  const steps = React.useMemo(() => {
    return [
      {
        label: "Select Athletes",
        content: (
          <Stack gap={3}>
            <Stack gap={3} direction="row" alignItems="center">
              <TextField
                type="number"
                value={minimumWieght}
                onChange={(e) => {
                  // TODO: Up button doesn't work
                  setMinimumWeight(
                    Number.parseFloat(
                      Number.parseFloat(e.target.value || "0").toFixed(1)
                    )
                  );
                }}
                inputProps={{
                  max: weightClass.maxWeight,
                }}
                label="Minimum Body Weight"
              />
              <TextField
                type="number"
                disabled={true}
                value={weightClass.maxWeight}
                label="Maximum Body Weight"
              />
            </Stack>
            <DataGrid
              isRowSelectable={(params: GridRowParams) => {
                if (selectedAthleteIds.includes(params.id)) {
                  return true;
                }
                const addedSelectionCount =
                  selectedAthleteIds.length - initialAthleteIds.length;

                if (athleteCount + addedSelectionCount >= maxAthletes) {
                  return false;
                }
                return true;
              }}
              selectionModel={selectedAthleteIds}
              onSelectionModelChange={handleSelectAthlete}
              autoHeight={true}
              rows={data || []}
              hideFooter={data?.length < 100}
              hideFooterSelectedRowCount={true}
              checkboxSelection={true}
              columns={[
                {
                  field: "lastName",
                  headerName: "Name",
                  minWidth: 200,
                  flex: 1,
                  renderCell: (params) => {
                    return `${params.row.lastName}, ${params.row.firstName}`;
                  },
                },
                {
                  field: "bodyWeight",
                  headerName: "Weight",
                  renderCell: (params) => {
                    return params.row.bodyWeight || params.row.baseBodyWeight;
                  },
                },
                {
                  field: "bestSquat",
                  headerName: "Best Squat",
                },
                {
                  field: "bestBench",
                  headerName: "Best Bench",
                },
                {
                  field: "bestDeadlift",
                  headerName: "Best Deadlift",
                },
              ]}
              components={{
                NoRowsOverlay: () => {
                  if (isLoading) {
                    return <CenteredCircularProgress />;
                  }
                  return (
                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      No athletes in this weight range.
                    </Stack>
                  );
                },
              }}
            />
          </Stack>
        ),
      },
      ...selectedAthleteIds.map((athleteId, index) => {
        return {
          label: "Enter Openers",
          content: (
            <AddUpdateMeetAthlete
              ref={(el) => (stepRefs.current[index] = el)}
              onUpdateAthlete={handleUpdateAthlete}
              athlete={stagedAthletes.find((a) => a.id === athleteId)}
              athleteId={athleteId}
              weightClass={weightClass}
              meetAthleteId={
                data?.find((a) => a.id === athleteId)?.meet_athleteId
              }
            />
          ),
        };
      }),
    ];
  }, [
    athleteCount,
    data,
    handleUpdateAthlete,
    initialAthleteIds.length,
    isLoading,
    maxAthletes,
    minimumWieght,
    selectedAthleteIds,
    stagedAthletes,
    weightClass,
  ]);

  const progress = React.useMemo(() => {
    if (activeStep === 0) {
      return 0;
    }

    return ((activeStep + 1) / steps.length) * 100;
  }, [activeStep, steps]);

  function handleKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Enter") {
      if (activeStep < steps.length - 1) {
        handleNext();
      } else {
        handleSubmit();
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        onClose();
      }}
      fullScreen={true}
      fullWidth={true}
      maxWidth="xl"
      sx={{ maxWidth: "100vw", minWidth: "100vw" }}
      onKeyUp={handleKeyUp}
    >
      <DialogTitle>
        {weightClass.weightClassName} - {steps[activeStep].label}
      </DialogTitle>

      <LinearProgress variant="determinate" value={progress} />
      <DialogContent
        dividers={true}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {steps[activeStep].content}
      </DialogContent>
      <DialogActions>
        <Stack
          width="100%"
          direction="row-reverse"
          justifyContent="space-between"
        >
          <Stack
            width="100%"
            direction="row-reverse"
            justifyContent="end"
            gap={1}
          >
            {activeStep < steps.length - 1 ? (
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            ) : (
              <LoadingButton
                onClick={handleSubmit}
                variant="contained"
                loading={isSubmitLoading}
                type="submit"
              >
                Submit
              </LoadingButton>
            )}
            {activeStep > 0 ? (
              <Button variant="outlined" onClick={handlePrevious}>
                Previous
              </Button>
            ) : null}
          </Stack>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
