import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";

import store from "../../data";
import AppActions from "../../data/app/actions";
import type { Athlete } from "../../types";

type AddAthleteDialogProps = {
  onClose: () => void;
  open: boolean;
  onSuccess: (athlete: Partial<Athlete>) => Promise<void>;
};

export function AddAthleteDialog({
  open,
  onClose,
  onSuccess,
}: AddAthleteDialogProps) {
  return (
    <AddEditAthleteDialog open={open} onClose={onClose} onSuccess={onSuccess} />
  );
}

type EditAthleteDialogProps = AddAthleteDialogProps & {
  athlete: Athlete;
};

export function EditAthleteDialog({
  open,
  onClose,
  onSuccess,
  athlete,
}: EditAthleteDialogProps) {
  return (
    <AddEditAthleteDialog
      open={open}
      onClose={onClose}
      onSuccess={onSuccess}
      athlete={athlete}
    />
  );
}

type AddEditAthleteDialogProps = {
  onClose: () => void;
  open: boolean;
  athlete?: Athlete;
  onSuccess: (athlete: Partial<Athlete>) => Promise<void>;
};

function AddEditAthleteDialog({
  open,
  onClose,
  athlete,
  onSuccess,
}: AddEditAthleteDialogProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<Athlete>({
    defaultValues: React.useMemo(() => athlete, [athlete]),
  });

  const [showHelp, setShowHelp] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      reset(athlete);
    }
  }, [reset, open, athlete]);

  async function onSubmit(data: Partial<Athlete>) {
    try {
      setIsLoading(true);
      // TODO: Do something with the result.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await onSuccess(data);

      await store.dispatch(
        AppActions.setSnackBar({
          text: `Successfully updated ${data.firstName} ${data.lastName}!`,
          severity: "success",
          open: true,
        })
      );
      onClose();
    } catch (error) {
      await store.dispatch(
        AppActions.setSnackBar({
          text: `Failed to update ${data.firstName} ${data.lastName}!`,
          severity: "error",
          open: true,
        })
      );
    }
    setIsLoading(false);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{athlete ? "Edit" : "Add"} Athlete</DialogTitle>

        <DialogContent
          dividers={true}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            {...register("firstName", { required: true })}
            error={!!errors.firstName}
            required={true}
            label="First Name"
            fullWidth={true}
          />
          <TextField
            {...register("lastName", { required: true })}
            error={!!errors.lastName}
            required={true}
            label="Last Name"
            fullWidth={true}
          />

          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth={true}
                select={true}
                label="Gender"
                error={!!errors.gender}
                required={true}
                helperText={errors.gender ? errors.gender?.message : ""}
              >
                <MenuItem value="MALE">Male</MenuItem>
                <MenuItem value="FEMALE">Female</MenuItem>
              </TextField>
            )}
          />
          <TextField
            {...register("grade", { required: true })}
            error={!!errors.grade}
            required={true}
            label="Grade"
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 1 }}
          />
          <Box
            p={3}
            border="1px solid"
            borderColor="divider"
            borderRadius={3}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Typography variant="h6">Base Values</Typography>
              <IconButton onClick={() => setShowHelp(true)}>
                <HelpOutlineIcon />
              </IconButton>
            </Stack>
            <TextField
              {...register("baseBodyWeight", { required: true })}
              error={!!errors.baseBodyWeight}
              label="Body Weight"
              required={true}
              type="number"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                step: 0.1,
              }}
            />
            <TextField
              {...register("baseStartSquat", { required: true })}
              error={!!errors.baseStartSquat}
              label="1st Squat"
              required={true}
              type="number"
              fullWidth={true}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 5 }}
            />
            <TextField
              {...register("baseStartBench", { required: true })}
              error={!!errors.baseStartBench}
              label="1st Bench"
              required={true}
              type="number"
              fullWidth={true}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 5 }}
            />
            <TextField
              {...register("baseStartDeadlift", { required: true })}
              error={!!errors.baseStartDeadlift}
              label="1st Deadlift"
              required={true}
              type="number"
              fullWidth={true}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 5 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton variant="contained" loading={isLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
      <Dialog open={showHelp} onClose={() => setShowHelp(false)}>
        <DialogTitle>Base Values</DialogTitle>

        <DialogContent
          dividers={true}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          The 'Base Values' are the values that are used to help you decide
          which athletes you would like to add to meets. When you add an athlete
          to a meet these values will be used as the starting values for that
          athlete. These values can be changed later on if you would like to
          adjust them.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowHelp(false)}>Okay</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}
